/* eslint-disable eqeqeq */
import React, { useState } from 'react'

import { withTranslation } from 'react-i18next'
import { gql } from '@apollo/client'
import {
    Button,
    Card,
    FormGroup,
    MenuItem,
    Paper,
    Select,
    Typography
} from '@material-ui/core'

import { useCurrentUser, useLastCompanyPeriod, useAllCurrencies } from '../../redux/selectors'

import Loading from '../../components/Loading/Loading'
import CurrencyPicker from '../../components/CurrencyPicker/CurrencyPicker'
import MonthPicker from '../../components/MonthPicker/MonthPicker'
import { thousandSeparator } from '../../utils/tools'
import CanvasJSReact from '../../utils/canvasjs.react'
import HomeConsumptionsTable from './HomeConsumptionsTable'

var CanvasJSChart = CanvasJSReact.CanvasJSChart

const query = gql`
    query {
        availableInventories {
            id
            serviceNumber
        }
    }
`

const dataQuery = gql`
    query($period:Period, $inventory:String, $currency:String) {
        periodLineConsumptions(period:$period, inventory:$inventory, currency:$currency) {
            consumptions {
                group {
                    name
                }
                inventory {
                    serviceNumber
                }
                cost
            }
            period
        }
    }
`

class ComponenteDeDatos extends React.Component {
    constructor(props) {
        super(props)
        this.toggleDataSeries = this.toggleDataSeries.bind(this)
        this.state = {
            selectedPeriod: 0
        }
    }

    getFilters = () => {
        return { period: `${this.props.year}-${this.props.month}`, inventory: this.props.inventory, currency: this.props.currency }
    }

    toggleDataSeries(e) {
        if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false
        }
        else {
            e.dataSeries.visible = true
        }
        this.chart.render()
    }

    selectPeriod = (newPeriod) => {
        this.setState({ selectedPeriod: newPeriod })
    }

    render() {
        const { t } = this.props
        return (
            <Loading query={dataQuery} variables={this.getFilters()}>
                {(data) => {
                    const consumptions_data = data.periodLineConsumptions[this.state.selectedPeriod].consumptions

                    function explodePie(e) {
                        if (typeof (e.dataSeries.dataPoints[e.dataPointIndex].exploded) === "undefined" || !e.dataSeries.dataPoints[e.dataPointIndex].exploded) {
                            e.dataSeries.dataPoints[e.dataPointIndex].exploded = true;
                        } else {
                            e.dataSeries.dataPoints[e.dataPointIndex].exploded = false;
                        }
                        e.chart.render();
                    }
                    var total = consumptions_data.reduce((previus_value, item) => item.cost + previus_value, 0);

                    var data_chart = consumptions_data.filter((item) => item.cost != 0);
                    data_chart = data_chart.map((item) => ({
                        y: Number(item.cost.toFixed(0)),
                        c: this.props.symbol,
                        p: ((Number(item.cost) / total) * 100).toFixed(0),
                        label: item.group.name,
                        t: thousandSeparator(item.cost)
                    }));

                    const options = {
                        // eslint-disable-next-line no-restricted-globals
                        height: (screen.height) * 0.35,
                        animationEnabled: true,
                        exportEnabled: true,
                        colorSet: "bubbleColors",
                        title: {
                            text: t("Personal Consumptions"),
                            fontFamily: "Calibri light",
                            fontColor: "#131f2e",
                        },
                        subtitles: [
                            {
                                text: this.props.symbol + total.toFixed(0),
                                fontFamily: "Calibri light",
                                fontColor: "#27abe1",
                                fontSize: 30,
                                verticalAlign: "center",
                                horizontalAlign: "center"
                            }
                        ],
                        legend: {
                            cursor: "default",
                            itemclick: explodePie
                        },
                        data: [
                            {
                                indexLabel: "{p}%",
                                indexLabelFontColor: "white",
                                innerRadius: "65%",
                                indexLabelPlacement: "inside",
                                toolTipContent: "{label}: {c} {t}",
                                indexLabelLineThickness: 0,
                                showInLegend: true,
                                legendText: "{label}",
                                type: "doughnut",
                                startAngle: -90,
                                dataPoints: data_chart,
                            }
                        ]
                    }

                    let charges = new Set();
                    for (let period_data of data.periodLineConsumptions) {
                        for (let charges_data of period_data.consumptions) {
                            charges.add(charges_data.group.name);
                        }
                    }

                    let stackedData = []
                    let dataPoints = []

                    for (let periodData of data.periodLineConsumptions) {
                        let value = 0;
                        for (let chargesData of periodData.consumptions) {
                            value = value + chargesData.cost;
                        }
                        if (value != 0) {
                            dataPoints.push({ label: periodData.period, c: this.props.symbol, y: value, t: thousandSeparator(value) })
                        }
                    }
                    stackedData.push({
                        toolTipContent: "{label}: {c} {t}",
                        type: "column",
                        name: t("Total"),
                        showInLegend: false,
                        yValueFormatString: "#,###",
                        dataPoints: dataPoints.reverse(),
                    })

                    const options_stacked = {
                        // eslint-disable-next-line no-restricted-globals
                        height: (screen.height) * 0.35,
                        animationEnabled: true,
                        exportEnabled: true,
                        title: {
                            text: t("Cost Evolution"),
                            fontFamily: "Calibri light",
                            fontColor: "#131f2e",
                        },
                        colorSet: "bubbleColor",
                        axisY: {
                            title: `${t('in')} ${(this.props.currency)}`,
                            prefix: this.props.symbol,
                        },
                        toolTip: {
                            shared: true,
                            reversed: true
                        },
                        legend: {
                            verticalAlign: "center",
                            horizontalAlign: "right",
                            reversed: true,
                            cursor: "pointer",
                            itemclick: this.toggleDataSeries
                        },
                        data: stackedData,
                    }

                    let i = 0;
                    let objetos = []
                    /*for (let data of data.periodLineConsumptions) {
                        objetos.push({pos: i, period: data.period})
                        i = i + 1;
                    }*/
                    for (let d of data.periodLineConsumptions) {
                        objetos.push({ pos: i, period: d.period })
                        i = i + 1;
                    }

                    return (
                        <>
                            <div style={{ position: "relative", paddingTop: 10}}>
                                <Paper style={{
                                    width: "8%",
                                    backgroundColor: "transparent",
                                    minWidth: 100,
                                    position: "absolute",
                                    textAlign: "center",
                                    marginLeft: "2%",
                                    marginTop: "5%",
                                    marginBottom: "-50%",
                                    zIndex: 9
                                }}
                                >
                                    {objetos.map((info, index) =>
                                        <div key={index}>
                                            <Button
                                                //color={(info.pos == this.state.selectedPeriod ) ? "primary" : null }
                                                color={(info.pos == this.state.selectedPeriod) ? "primary" : "default"}
                                                onClick={() => this.selectPeriod(info.pos)}
                                            >
                                                {info.period}
                                            </Button>
                                        </div>
                                    )}
                                </Paper>
                                <Card style={{ paddingTop: 10, paddingRight: 10, marginBottom: 20 }}>
                                    <CanvasJSChart
                                        containerProps={{
                                            width: "50%",
                                            position: "relative",
                                            float: "left",
                                            paddingRight: 10
                                        }}
                                        id="PieChart"
                                        options={options}
                                    />
                                    <CanvasJSChart
                                        containerProps={{
                                            width: "50%",
                                            position: "relative",
                                            float: "right",
                                            paddingLeft: 10
                                        }}
                                        options={options_stacked}
                                        onRef={ref => this.chart = ref}
                                    />
                                </Card>
                                <HomeConsumptionsTable
                                    t={this.props.t} 
                                    filters={{...this.getFilters(), filterUserConsumptions: true}}  
                                    symbol={this.props.symbol}
                                />
                            </div>
                        </>
                    )
                }}
            </Loading>
        )
    }
}

const Home = (props) => {
    const { t } = props
    const currentUser = useCurrentUser();
    const LastCompanyPeriod = useLastCompanyPeriod();
    const allCurrencies = useAllCurrencies();

    document.title = `${props.t('root:Company Home')} - BubbleBPM`

    const [month, setMonth] = useState(LastCompanyPeriod.month)
    const [year, setYear] = useState(LastCompanyPeriod.year)
    const [selected, setSelected] = useState(null)
    const [currencyCode, setCurrencyCode] = useState(currentUser.currency ? currentUser.currency.code : allCurrencies[0].node.id)
    const [currencySymbol, setCurrencySymbol] = useState(currentUser.currency ? currentUser.currency.symbol : allCurrencies[0].node.symbol)

    const onChangeDate = (year, month) => {
        setYear(year)
        setMonth(month)
    }

    const handleChange = (event) => {
        setSelected(event.target.value)
    }

    const changeCurrency = (currencyCode, symbol) => {
        setCurrencyCode(currencyCode)
        setCurrencySymbol(symbol)
    }

    const getLines = (data) => {
        let items = [{id:'0', serviceNumber:t('stateFilter:ALL')}]
        {data.availableInventories.map((item_data) => (
            items.push({id:item_data.id || null, serviceNumber: item_data.serviceNumber})
        ))}

        return items
    }

    return (
        <div id="app">
            <Loading query={query} showLoading={false}>
                {(data) => {
                    return (
                        <>
                            <Card>
                                <div>
                                    <Typography style={{ paddingLeft: 30 }} variant="h4">
                                        {t("Hello") + " " + currentUser.firstName + " " + (currentUser.middleName ? (currentUser.middleName + " ") : "") + currentUser.lastName}
                                    </Typography>
                                </div>

                                <div>
                                    <Typography style={{ paddingLeft: 30 }} variant="h6">
                                        {t("Email") + ": " + currentUser.email + (currentUser.manager ? (" (" + t("Manager") + ": " + currentUser.manager.firstName + " " + currentUser.manager.lastName + ") ") : "")}
                                    </Typography>
                                </div>
                            </Card>

                            <Card style={{ marginTop: 10 }}>
                                <FormGroup style={{ paddingTop: 10, paddingBottom: 10, width: "100%" }}>
                                    <div style={{ display: 'flex', flexWrap: "no-wrap", justifyContent: "space-around", width: "100%" }}>

                                        <div style={{ display: 'flex', width: "30%" }}>
                                            <span style={{ marginTop: '5px', marginLeft: '10%', color: '#0000008a' }}>{t('Reference Period')}</span>
                                            <MonthPicker style={{ marginLeft: "1%" }}
                                                month={month}
                                                year={year}
                                                onChangeDate={onChangeDate}
                                                checkDisabledMonths={true}
                                                t={t}
                                            />
                                        </div>

                                        <div style={{ width: "30%" }}>
                                            <span style={{ marginLeft: '10%', color: '#0000008a' }}>{t('Line Filter')}</span>
                                            <Select style={{ marginLeft: "1%" }} value={selected || '0'} onChange={handleChange}>
                                                {getLines(data).map((item_data) => (
                                                    <MenuItem key={item_data.id || null} value={item_data.id || null}>
                                                        {item_data.serviceNumber || null}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>


                                        <div style={{ width: "30%" }}>
                                            <CurrencyPicker
                                                month={month}
                                                year={year}
                                                selectedSymbol={currencySymbol ? currencySymbol : currentUser.currency.symbol}
                                                selected={currencyCode ? currencyCode : currentUser.currency.code}
                                                onChangeCurrency={changeCurrency}
                                                style={{ marginLeft: 10 }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                            </Card>

                            <ComponenteDeDatos
                                t={props.t}
                                symbol={currencySymbol}
                                currency={currencyCode}
                                year={year}
                                month={month}
                                inventory={selected}
                                onChangeDate={onChangeDate}
                            />
                        </>
                    )
                }}
            </Loading>
        </div>
    )
}

export default withTranslation('home')(Home)
