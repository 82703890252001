import { gql } from '@apollo/client'
import * as f from '../../../components/BPMTable/BPMInputs'
import {
    TableFieldDescription, 
    DataMapper,
    NullClickStrategy,
    ProcessControlSubProcessesDisplayStrategy,
} from '../../../components/BPMTable/TableMapperCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query AvailableCompanyPeriodSubProcesses {
        availableCompanyPeriodSubProcesses {
            edges {
                node {
                    id
                    taskName
                }
            }
            count
        }
    }
`

let editMutator

let newMutator

let deleteMutator

let unitQuery

const InvoicePeriodSubProcessTableCreatorCreatorQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class InvoicePeriodSubProcessTableCreatorCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InvoicePeriodSubProcessTableCreatorCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(
            this.constructor.getId(), 
            tableName, 
            InvoicePeriodSubProcessTableCreatorCreatorQueries, 
            new NullClickStrategy(), 
            new ProcessControlSubProcessesDisplayStrategy());
        tableCreator.setDefaultRowHeight(25);

        tableCreator.setDescriptions([
            new TableFieldDescription(
                'taskName',
                new DataMapper("taskName"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Process', hideSort: true}
            )
        ])

        return tableCreator
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InvoicePeriodSubProcessTableCreatorCreator)

export default InvoicePeriodSubProcessTableCreatorCreator
