import React, { useState } from 'react'

import { withTranslation } from 'react-i18next'

import { Popper, ClickAwayListener, Button } from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'

import { useFirstCompanyPeriod, useLastCompanyPeriod, useLatestPeriodForProcessedInvoices, useFirstPeriodForProcessedInvoices } from '../../redux/selectors'

import PeriodsGrid from '../PeriodsGrid/PeriodsGrid'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForward from '@material-ui/icons/ArrowForward'
import { ButtonTooltip } from '../Tooltips/BPMTooltips'

const MonthPicker = (props) => {
    const useInvoicePeriods = props.useInvoicePeriods
    const FirstCompanyPeriod = useFirstCompanyPeriod()
    const LastCompanyPeriod = useLastCompanyPeriod()
    const FirstPeriodForProcessedInvoices = useFirstPeriodForProcessedInvoices()
    const LatestPeriodForProcessedInvoices = useLatestPeriodForProcessedInvoices()

    const [anchorEl, setAnchorEl] = useState(null)
    const [year, setYear] = useState(props.year)
    const [month, setMonth] = useState(props.month)
    const [initialYear, setInitialYear] = useState(props.useInvoicePeriods ? FirstPeriodForProcessedInvoices.year : FirstCompanyPeriod.year )

    const getFirstRealCompanyPeriod = () => {
        return useInvoicePeriods ? FirstPeriodForProcessedInvoices :  FirstCompanyPeriod
    }

    const getLastRealCompanyPeriod = () => {
        return useInvoicePeriods ? LatestPeriodForProcessedInvoices : LastCompanyPeriod
    }

    const setDate = (aYear, aMonth) => {
        var currentDate = new Date();
        const currentYear = currentDate.getUTCFullYear();
        const currentMonth = currentDate.getUTCMonth() + 1;

        const isFutureDate = aYear > currentYear || (aYear === currentYear && aMonth > currentMonth);
        const newYear = isFutureDate ? currentYear : aYear;
        const newMonth = isFutureDate ? currentMonth : aMonth;

        setYear(newYear);
        setMonth(newMonth);

        if (props.onChangeDate) {
            props.onChangeDate(newYear, newMonth);
        }
    }

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    const close = () => {
        setAnchorEl(null)
        setYear(initialYear)
    }

    const onSetDate = (newYear, newMonth) => {
        setDate(newYear, newMonth);
        setAnchorEl(null)
        setInitialYear(year)
    }

    const reduceMonth = () => {
        var newMonth = month;
        var newYear = year;

        if (month === 1) {
            newMonth = 12;
            newYear--;
        } else {
            newMonth--;
        }

        setDate(newYear, newMonth);
        setAnchorEl(null)
        setInitialYear(newYear)
    }

    const increaseMonth = () => {
        var newMonth = month;
        var newYear = year;

        if (month === 12) {
            newMonth = 1;
            newYear++;
        } else {
            newMonth++;
        }

        setDate(newYear, newMonth);
        setAnchorEl(null)
        setInitialYear(newYear)
    }

    const generateDate = () => {
        const month_string = (month < 10) ? "0" + Number(month) : month

        return (year + "/" + month_string)
    }

    const checkPrevNextMonthIsDisabled = (checkNext) => {
        if ((typeof props.checkDisabledMonths !== 'undefined') && !props.checkDisabledMonths) {
            return false
        }

        if (checkNext) {
            if (year === getLastRealCompanyPeriod().year) {
                return month >= getLastRealCompanyPeriod().month
            } else {
                return false
            }
        } else {
            if (year === getFirstRealCompanyPeriod().year) {
                return month <= getFirstRealCompanyPeriod().month
            } else {
                return false
            }
        }
    }

    return (
        <div style={{ display: "flex", width: "20%" }}>
            <ButtonTooltip title={props.t('translations:Previous month')}>
                <span>
                    <Button disabled={checkPrevNextMonthIsDisabled(false)} onClick={() => { reduceMonth() }}>
                        <ArrowBackIcon />
                    </Button>
                </span>
            </ButtonTooltip>
            <div>
                <Button style={props.style} onClick={handleClick} ><CalendarTodayIcon style={{ paddingRight: 5 }} />
                    {generateDate()}
                </Button>
            </div>
            <ButtonTooltip title={props.t('translations:Next month')}>
                <span>
                    <Button disabled={checkPrevNextMonthIsDisabled(true)} onClick={() => { increaseMonth() }}>
                        <ArrowForward />
                    </Button>
                </span>
            </ButtonTooltip>

            <Popper
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                popperOptions={{ onCreate: (data) => data.instance.popper.style.zIndex = 1 }}
            >
                <ClickAwayListener onClickAway={close}>
                    <div>
                        <PeriodsGrid
                            year={year}
                            setDate={onSetDate}
                            checkDisabledMonths={true}
                            useInvoicePeriods={useInvoicePeriods}
                        />
                    </div>
                </ClickAwayListener>
            </Popper>
        </div>
    )
}

export default withTranslation("monthPicker")(MonthPicker)