/* eslint-disable eqeqeq */
import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper,
    TableFieldDescription,
    DateComponentCreator,
    DateTimeComponentCreator,
    DateDisplayCreator
} from '../../../components/BPMTable/TableMapperCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'


const tableQuery = gql`
    query AllCompanyPeriod(
        $orderField: String,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $datePeriod: String,
        $startDate: String,
        $endDate: String,
    ) {
        allCompanyPeriod(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            datePeriod:$datePeriod,
            endDate:$endDate,
            startDate:$startDate
        ) {
            edges {
                node {
                    id
                    period {
                        id
                        date
                        endDate
                        startDate
                    }
                    lastExecutionDate
                    timesProcessed
                    availProcessses
                    executedProcesses
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const newMutator = gql`
    mutation createCompanyPeriodMutation($periodId: String) {
        createCompanyPeriod(periodId: $periodId) {
            companyPeriod {
                id
                period {
                    date
                }
            }
        }
    }
`
const unitQuery = `
    companyPeriod(id: $id) {
        id
        period {
            id
            date
            endDate
            startDate
        }
        lastExecutionDate
        timesProcessed
        availProcessses
        executedProcesses
    }
`

const InvoiceInvoicePeriodsTableCreatorQueries = {tableQuery, newMutator, unitQuery}

class InvoiceInvoicePeriodsTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InvoiceInvoicePeriodsTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, InvoiceInvoicePeriodsTableCreatorQueries)
        tableCreator.setDescriptions([
            new TableFieldDescription(
                'startDate',
                new DataMapper('period.date'),
                new f.FilterDescription(new f.PeriodFilter('startDate')),
                {label: ('Period')}
            ),
            new TableFieldDescription(
                'lastExecutionDate',
                new DataMapper('lastExecutionDate'),
                new f.FilterDescription(new f.DateFilter('lastExecutionDate')),
                {label: ('Last Execution'), componentCreator: new DateTimeComponentCreator(), excelDisplay: new DateDisplayCreator()}
            ),
            new TableFieldDescription(
                'timesProcessed',
                new DataMapper('timesProcessed'),
                new f.FilterDescription(new f.NullCreator()),
                {label: ('Times Processed'), hideSort: true}
            ),
            new TableFieldDescription(
                'availProcessses',
                new DataMapper('availProcessses'),
                new f.FilterDescription(new f.NullCreator()),
                {label: ('Avail Processes'), hideSort: true}
            ),
            new TableFieldDescription(
                'executedProcesses',
                new DataMapper('executedProcesses'),
                new f.FilterDescription(new f.NullCreator()),
                {label: ('Executed Processes'), hideSort: true}
            ),
            new TableFieldDescription(
                'endDate',
                new DataMapper('period.endDate'),
                new f.FilterDescription(new f.DateFilter('endDate')),
                {label: ('End Date'), componentCreator: new DateComponentCreator(), excelDisplay: new DateDisplayCreator()}
            )
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InvoiceInvoicePeriodsTableCreator)

export {
    InvoiceInvoicePeriodsTableCreator,
    InvoiceInvoicePeriodsTableCreatorQueries
}