import { useSelector, shallowEqual } from "react-redux";
import _ from 'lodash';

const BPMTableStates = {
    count: 'COUNT',
    filters: 'FILTERS',
    orderField: 'ORDER_FIELD', 
    orderDirection: 'ORDER_DIRECTION',
    selectAll: 'SELECT_ALL',
    selectedRows: 'SELECTED_ROWS',
    selectedRefs: 'SELECTED_REFS',
    tableColumns: 'TABLE_COLUMNS'
}

const compareStateObject = (newState, prevState) => {
    return _.isEqual(newState, prevState);
};

export function useTableStates(tableName) {
    const company = useCurrentUserCompany()
    return useSelector(state => {
        if (company && company.id) {
            if (state.BPMTables[company.id].hasOwnProperty(tableName)) {
                return state.BPMTables[company.id][tableName];
            }
        }

        return null;
    }, shallowEqual);
}

export function useTableCount(tableName) {
    const company = useCurrentUserCompany()
    return useSelector(state => {
        if (company && company.id) {
            if (state.BPMTables[company.id].hasOwnProperty(tableName)) {
                return state.BPMTables[company.id][tableName].count;
            }
        }

        return 0;
    }, shallowEqual);
}

export function useTableFilters(tableName) {
    const company = useCurrentUserCompany()
    return useSelector(state => {
        if (company && company.id) {
            if (state.BPMTables[company.id].hasOwnProperty(tableName)) {
                return state.BPMTables[company.id][tableName].filters;
            }
        }
        
        return {}
    }, shallowEqual);
}

export function useTableOrderField(tableName) {
    const company = useCurrentUserCompany()
    return useSelector(state => {
        if (company && company.id) {
            if (state.BPMTables[company.id].hasOwnProperty(tableName)) {
                return state.BPMTables[company.id][tableName].filters.orderField;
            }
        }

        return null
    }, shallowEqual);
}

export function useTableOrderDirection(tableName) {
    const company = useCurrentUserCompany()
    return useSelector(state => {
        if (company && company.id) {
            if (state.BPMTables[company.id].hasOwnProperty(tableName)) {
                return state.BPMTables[company.id][tableName].filters.orderDirection;
            }
        }

        return null
    }, shallowEqual);
}

export function useTableSelectAllState(tableName) {
    const company = useCurrentUserCompany()
    return useSelector(state => {
        if (company && company.id) {
            if (state.BPMTables[company.id].hasOwnProperty(tableName)) {
                return state.BPMTables[company.id][tableName].selectAllState.selectAll;
            }
        }

        return false
    }, shallowEqual);
}

export function useTableSelectedRows(tableName) {
    const company = useCurrentUserCompany()
    return useSelector(state => {
        if (company && company.id) {
            if (state.BPMTables[company.id].hasOwnProperty(tableName)) {
                return state.BPMTables[company.id][tableName].selectAllState.selectedRows;
            }
        }

        return []
    }, shallowEqual);
}

export function useTableSelectedRefs(tableName) {
    const company = useCurrentUserCompany()
    return useSelector(state => {
        if (company && company.id) {
            if (state.BPMTables[company.id].hasOwnProperty(tableName)) {
                return state.BPMTables[company.id][tableName].selectAllState.selectedRefs;
            }
        }

        return []
    }, shallowEqual);
}

export function useTableColumns(tableName) {
    return useSelector(state => {
        if (state.customConfiguration.hasOwnProperty(tableName)) {
            return state.customConfiguration[tableName]
        }
        return [];
    }, compareStateObject);
}

export function useTableColumnConfig(tableName, columnId) {
    return useSelector(state => {
        const config = state.customConfiguration[tableName];
        if (config) {
            return config.find(item => item.id === columnId);
        }
        return null;
    }, shallowEqual);
}

export function useCurrentUser() {
    return useSelector(state => {
        return _.get(state, 'currentUser', {})
    }, compareStateObject);
}

export function useCurrentUserCompany() {
    return useSelector(state => {
        return _.get(state, 'currentUser.company', {})
    }, shallowEqual);
}

export function useCurrentUserMembershipSet() {
    return useSelector(state => {
        return _.get(state, 'currentUser.membershipSet', {})
    }, shallowEqual);
}

export function useAllCurrencies() {
    return useSelector(state => {
        return _.get(state, 'allCurrencies', [])
    }, shallowEqual);
}

export function useAllLanguages() {
    return useSelector(state => {
        return _.get(state, 'allLanguages', [])
    }, shallowEqual);
}


export function useFirstCompanyPeriod() {
    const defaultDate = new Date()
    const defaultPeriod = {year: defaultDate.getFullYear(), month: defaultDate.getMonth()+1}
    const company = useCurrentUserCompany()
    return useSelector(state => {
        if (company && company.id) {
            return _.get(state, `firstCompanyPeriod.${company.id}`, defaultPeriod)
        }

        return defaultPeriod
    }, shallowEqual);
}

export function useLastCompanyPeriod() {
    const defaultDate = new Date()
    const defaultPeriod = {year: defaultDate.getFullYear(), month: defaultDate.getMonth()+1}
    const company = useCurrentUserCompany()
    return useSelector(state => {
        if (company && company.id) {
            return _.get(state, `lastCompanyPeriod.${company.id}`, defaultPeriod)
        }

        return defaultPeriod
    }, shallowEqual);
}

export function useFirstPeriodForProcessedInvoices() {
    const defaultDate = new Date()
    const defaultPeriod = {year: defaultDate.getFullYear(), month: defaultDate.getMonth()+1}
    const company = useCurrentUserCompany()
    return useSelector(state => {
        if (company && company.id) {
            return _.get(state, `firstPeriodForProcessedInvoices.${company.id}`, defaultPeriod)
        }

        return defaultPeriod
    }, shallowEqual);
}

export function useLatestPeriodForProcessedInvoices() {
    const defaultDate = new Date()
    const defaultPeriod = {year: defaultDate.getFullYear(), month: defaultDate.getMonth()+1}
    const company = useCurrentUserCompany()
    return useSelector(state => {
        if (company && company.id) {
            return _.get(state, `latestPeriodForProcessedInvoices.${company.id}`, defaultPeriod)
        }

        return defaultPeriod
    }, shallowEqual);
}

export function useApplicationSnackMessages() {
    return useSelector(state => {
        return _.get(state, 'applicationSnackMessages', [])
    }, compareStateObject);
}