import React, { useState, useEffect } from 'react'

import {
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
} from "@material-ui/core";

import { gql, useApolloClient } from '@apollo/client';
import { useDispatch } from 'react-redux';

import { AddBPMApplicationMessages, setBPMTableSelectAllState } from '../../../redux/reducers/BPMReducer';
import { useTableSelectedRows, useTableSelectAllState, useTableCount, } from '../../../redux/selectors'
import BPMDialog from '../../../components/BPMTable/TableComponents/Dialogs/BPMDialog'

import SetupTableData from '../../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'

import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import InvoicePeriodSubProcessTableCreatorCreator from '../../../tableDefinitions/Invoice/Periods/InvoicePeriodSubProcessTableCreatorCreator'

const PROCESS_COMPANY_PERIOD = gql `
    mutation(
        $companyPeriodIds: [String], 
        $allCompanyPeriods: Boolean, 
        $subProcesses: [String], 
        $allSubProcesses: Boolean
    ){
        executeCompanyPeriodProcess(
            companyPeriodIds:$companyPeriodIds, 
            allCompanyPeriods: $allCompanyPeriods, 
            subProcesses: $subProcesses, 
            allSubProcesses: $allSubProcesses
        ) {
            success
        }
    }
`;

const ProcessCompanyPeriodDialog = props => {
    const processesTableName = 'Period Processors'
    const [ isLoading, setLoading ] = useState(false);

    const { open, handleClose, allRowsSelected, selectedRows, t } = props;

    const selectedProcesses = useTableSelectedRows(processesTableName)
    const processesAllRowsSelected = useTableSelectAllState(processesTableName)
    const processesCount = useTableCount(processesTableName)

    const dispatch = useDispatch()
    const client = useApolloClient()

    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(InvoicePeriodSubProcessTableCreatorCreator.getId(),  {tcFilters: {}})
    const tableCreator = tableCreatorCreator.getTableCreator(processesTableName)
    tableCreator.setShowPopupChangeHistoryPanel(false)

    const onClickProcess = () => {
        setLoading(true)

        client.mutate({
            mutation: PROCESS_COMPANY_PERIOD,
            variables: getQueryVariables(),
        })
        .then(result => {
            const success = result.data;
            if (success && success.executeCompanyPeriodProcess.success) {
                handleQueryExecuted(true, t("Tasks for processing selected periods have been created"))
            } else {;
                handleQueryExecuted(false, t('An error occurred trying process the selected periods'))
            }
        })
        .catch(error => {
            handleQueryExecuted(false, JSON.stringify(error))
        })
        .finally(() => {    
            setLoading(false)
        })
    };

    const getQueryVariables = () => {
        return {
            companyPeriodIds: selectedRows ? selectedRows.map(row => row.id) : [],
            allCompanyPeriods: allRowsSelected,
            subProcesses: selectedProcesses ? selectedProcesses.map(row => row.taskName) : [],
            allSubProcesses: processesAllRowsSelected,
        }
    }

    const createApplicationMessage = (text, variant) => {
        dispatch(AddBPMApplicationMessages({
            text: text, 
            duration: 8000,
            variant: variant,
        }))
    }

    const handleQueryExecuted  = (success, message) => {
        setLoading(false);
        onCloseDialog();
        createApplicationMessage(message, success ? 'success' : 'error')
    }

    const onCloseDialog = () => {
        dispatch(setBPMTableSelectAllState({
            tableName: processesTableName, 
            selectAllState: {selectAll: false, selectedRows: [], selectedRefs: []}
        }))
        handleClose()
    }

    const getSelectedProcessesCount = () => {
        if (!selectedProcesses) return 0
        return processesAllRowsSelected ? processesCount - selectedProcesses.length : selectedProcesses.length;
    }

    const getSelectedProcessesLabel = () => {
        return processesCount > 0 ?
            t('tableFooter:Selected Processes') + ': ' + getSelectedProcessesCount() + ' ' + t('tableFooter:of') + ' ' + processesCount
        : null
    }

    return (
        <>
            <BPMDialog 
                open={open} 
                onClose={onCloseDialog}
                maxWidth='md'
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    <span onClick={(event, reason) => onCloseDialog(event, reason)} style={{fontSize: '1.5em',  cursor: 'pointer', position: 'absolute', right: '5px', top: '-5px'}}>&times;</span>
                    {t('tableFooter:Process selected Periods')}
                </DialogTitle>
                <DialogContent>
                    {!allRowsSelected && (!selectedRows || selectedRows.length === 0) ?
                        <Typography variant='body1'>
                            {t('tableFooter:You must select at least one Period to process')}
                        </Typography>
                    :
                        <SetupTableData
                            TableCreator={tableCreator}
                            stickyHeader
                        />
                    }   
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <div style={{ textAlign: 'left', paddingLeft: 15 }}>
                        <Typography variant='body1'>
                            {getSelectedProcessesLabel()}
                        </Typography>
                    </div>

                    <div>
                        <Button 
                            style={{ marginRight: 20 }}
                            onClick={onCloseDialog} 
                            variant='contained'
                        >
                            {t('tableFooter:Cancel')}
                        </Button>
                        <Button
                            onClick={onClickProcess}
                            variant='contained'
                            color='primary'
                            disabled={isLoading || getSelectedProcessesCount() == 0 || (!allRowsSelected && (!selectedRows || selectedRows.length === 0))}
                        >
                            {t('tableFooter:Process')}
                        </Button>
                    </div>
                </DialogActions>
            </BPMDialog>
        </>
    )
};

export default ProcessCompanyPeriodDialog;
