import React from 'react'

import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import SetupTableData from '../../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'
import InvoicePeriodSubProcessUnitQueryTableCreatorCreator from '../../../tableDefinitions/Invoice/Periods/InvoicePeriodSubProcessUnitQueryTableCreatorCreator'
import tableCreatorCreatorFactoryCreator from "../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator"


export default function ProcessCompanyPeriodPopupContent (props) {
    const { t } = useTranslation()
    const filters = { companyPeriodId: props.data.rawData.companyPeriod.id }
    const executedProcesses = props.data.rawData.companyPeriod.executedProcesses

    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(InvoicePeriodSubProcessUnitQueryTableCreatorCreator.getId(),  {tcFilters: filters})
    const tableCreator = tableCreatorCreator.getTableCreator('Period SubProcesses')

    return (
        <div>
            { executedProcesses ?
                <SetupTableData
                    TableCreator={tableCreator}
                    stickyHeader>
                    {() =>
                        <div style={{ position: 'sticky', top: 2, background: 'white', height:'35px' }}>
                            <span onClick={(event, reason) => props.close(event, reason)} style={{fontSize: '1.5em',  cursor: 'pointer', position: 'absolute', right: '5px', top: '-5px'}}>&times;</span>
                            <Typography variant="h6" style={{ marginLeft:'10px' }}>{t(`Period SubProcesses`)}</Typography>
                        </div>
                    }
                </SetupTableData>
            :
                <div style={{ padding: '10px', position: 'relative' }}>
                    <div style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px', marginBottom: '10px' }}>
                        <Typography variant="h6" style={{ marginLeft: '10px' }} onClick={(event, reason) => props.close(event, reason)}>
                            {t(`No Sub-Processes`)}
                        </Typography>
                    </div>
                    <Typography variant="body1" style={{ marginLeft: '10px' }}>
                        {t(`Looks like this period has not been processed yet, no sub-processes were found`)}
                    </Typography>
                    <div style={{ textAlign: 'right', marginTop: '10px', paddingTop: '30px' }}>
                        <button
                            onClick={(event, reason) => props.close(event, reason)}
                            style={{
                                background: '#007bff',
                                color: 'white',
                                border: 'none',
                                padding: '5px 10px',
                                borderRadius: '3px',
                                cursor: 'pointer',
                            }}
                        >
                            {t(`Close`)}
                        </button>
                    </div>
                </div>
            }                              
        </div>
    )
}
