/* eslint-disable eqeqeq */
import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useApolloClient } from '@apollo/client'

import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'

import BPMTable from '../../../components/BPMTable/BPMTable'

import { PeriodDisplayStrategy, PeriodSelectedActions } from '../../../components/BPMTable/TableMapperCreator'
import { ExportFooterItemCreator } from '../../../components/BPMTable/TableFooterItems'

import ProcessCompanyPeriodDialog from '../../../pages/Invoice/Periods/ProcessCompanyPeriodDialog'
import ProcessCompanyPeriodPopupContent from '../../../pages/Invoice/Periods/ProcessCompanyPeriodPopupContent'
import { customPopUpContentDisplayStrategy } from '../../../components/BPMTable/TableMapperCreator'

import InvoiceInvoicePeriodsFormCreator from '../../../tableDefinitions/Invoice/Periods/InvoiceInvoicePeriodsFormCreator'
import { InvoiceInvoicePeriodsTableCreator } from '../../../tableDefinitions/Invoice/Periods/InvoiceInvoicePeriodsTableCreator'

const Periods = (props) => {
    const { t } = useTranslation()
    const client = useApolloClient()
    const [openDialog, setOpenDialog] = useState(false)

    document.title = `${t('Periods')} - BubbleBPM`
    const path = props.match.url

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }
    
    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const getDialogState = () => {
        return openDialog
    }

    const processCompanyPeriodDialog = {
        dialog: ProcessCompanyPeriodDialog, 
        openDialogState: getDialogState, 
        colseDialogAction: handleCloseDialog
    }

    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(InvoiceInvoicePeriodsTableCreator.getId(), 
        {tcFilters: {}})
    tableCreatorCreator.setClient(client)

    const tableCreator = tableCreatorCreator.getTableCreator('Periods')
    tableCreator.setAdditionalFilters({first:30})
    tableCreator.setInitialSortField('startDate', 'desc')
    tableCreator.setSelectedRowActionsStrategy( new PeriodSelectedActions(handleOpenDialog))
    tableCreator.setDisplayStrategy(new PeriodDisplayStrategy())
    tableCreator.setTableFooterItems([new ExportFooterItemCreator()])
    tableCreator.setPopupContentStrategy(new customPopUpContentDisplayStrategy(ProcessCompanyPeriodPopupContent));
    tableCreator.setTableFooterDialogs([processCompanyPeriodDialog])
    tableCreator.setShowPopupChangeHistoryPanel(false);

    var formCreatorCreator = formCreatorCreatorFactoryCreator.getFormCreatorCreatorFromIdentifier(InvoiceInvoicePeriodsFormCreator.getId(), null)
    const formCreator = formCreatorCreator.getFormCreator()

    return (
        <>
            <BPMTable
                TableCreator={tableCreator}
                FormCreator={formCreator}
                path={path}
            />
        </>
    )
}

export default Periods