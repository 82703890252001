import React from 'react'

import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core'
import { Description as DescriptionIcon } from '@material-ui/icons'
import { Chip } from '@material-ui/core'
import { 
    AutorenewOutlined, 
    CheckCircleOutline, 
    HighlightOffOutlined, 
    ScheduleOutlined 
} from '@material-ui/icons'
import { useAxiosClient } from '../contexts/Axios'
import TranslationProvider from '../translations/TranslationProvider';

const DurationCalculator = (props) => {
    const {t} = useTranslation();

    const diffLabel = () => {
        const days = Math.floor(props.delta / (1000 * 60 * 60 * 24))
        const hours = Math.floor((props.delta / (1000 * 60 * 60)) % 24)
        const minutes = Math.floor((props.delta / (1000 * 60)) % 60)
        const seconds = props.date.getSeconds()
        const millisecoonds = props.date.getMilliseconds()
        

        const renderDays = days > 0 ? `${days} ${days > 1 ? t('Days') : t('Day')}` : ''
        const renderHours = hours > 0 ? `${hours} ${hours > 1 ? t('Hours') : t('Hour')}` : ''
        const renderMinutes = minutes > 0 ? `${minutes} ${minutes > 1 ? t('Minutes') : ('Minute')}` : ''
        const renderSeconds = seconds > 0 ? `${seconds} ${seconds > 1 ? t('Seconds') : ('Second')}` : ''
        const renderMilliseconds = (minutes == 0 && hours == 0 && days == 0) ? `${millisecoonds} ${millisecoonds > 1 || millisecoonds === 0 ? t('Milliseconds') : t('Millisecond')}` : ''

        if (props.delta) {
            return `${renderDays} ${renderHours} ${renderMinutes} ${renderSeconds} ${renderMilliseconds} ${props.prefix ? t(props.prefix) : ''}`
        } else {
            return ' - '
        }
    }
    
    return (
        diffLabel(t)
    )
}

const dateDiff = date => {
    const delta = new Date(Date.now()) - new Date(date).getTime()
    const a_date = new Date(delta)

    return (
        <DurationCalculator
            delta = {delta}
            date = {a_date}
            prefix = {'ago'}
        />
    )
}

const checkProcessDuration = (duration, createdAt, updatedAt, state) => {
    if (state == 'NOT_STARTED') {
        return ""
    } else if (state == 'IN_PROCESS') {
        return dateDiff(createdAt)
    } else {
        return parseDuration(duration)
    }
}
    
const parseDuration = (duration) => {
    const delta = Date.parse(duration)
    const a_date = new Date(delta)

    return (
        <DurationCalculator
            delta = {delta}
            date = {a_date}
        />
    )
}
    
const createStateChip = (data) => {
    const stateProperties = {
        'FINISHED': {color: 'green', icon: <CheckCircleOutline style={{color: 'green'}}/>},
        'ERROR': {color: 'red', icon: <HighlightOffOutlined style={{color: 'red'}}/>},
        'FAILED': {color: 'red', icon: <HighlightOffOutlined style={{color: 'red'}}/>},
        'IN_PROCESS': {color: 'black', icon: <AutorenewOutlined style={{color: 'black'}}/>},
        'NOT_STARTED': {color: 'grey', icon: <ScheduleOutlined style={{color: 'grey'}}/>},
    };
    const state = stateProperties[data] || {icon: null}

    return (
        <TranslationProvider>
            {t =>(
                <Chip
                    variant="outlined"
                    icon={state.icon}
                    size="small"
                    label={t(data, t)}
                    style={{color: state.color, border: state.color, borderStyle: 'solid', borderWidth: '1px'}}
                />
            )}
        </TranslationProvider>
    )
}


const onClickDownloadFile = (path, client, id, creationDT, model) => {
        client.get(
        `${path}${id}`,
        {
            responseType: 'blob'
        }
    ).then(
        response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a', {key: `link${id}`})
            link.href = url
            const aDate = creationDT ? creationDT : new Date().toISOString()
            const fileName = (model ? model : 'Resource') + '_' + aDate.replace(/\D/g, '').slice(0, 14) + '.xlsx'
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
        }
    ).catch(
        error => console.log(error)
    )
}

const ProcessControlDownloadFile = props => {
    const { axiosClient } = useAxiosClient()
    return (
        <div key={`fileKey=${props.id}`} style={{textAlign:'center'}}>
            <IconButton onClick={ event => { event.stopPropagation(); onClickDownloadFile(props.path, axiosClient, props.id, props.creationDT, props.model)}}>
                <DescriptionIcon />
            </IconButton>
        </div>
    )
}

export {
    dateDiff,
    checkProcessDuration,
    parseDuration,
}

