import { gql } from '@apollo/client'
import * as f from '../../../components/BPMTable/BPMInputs'
import {
    TableFieldDescription, 
    DataMapper,
    DataTransformer,
    StatusChipComponentCreator,
    NullClickStrategy,
    SimpleTableDisplayStrategy,
    DateTimeComponentCreator,
    DateDisplayCreator
} from '../../../components/BPMTable/TableMapperCreator'
import { checkProcessDuration } from '../../../utils/processControlUtils'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query CompanyPeriodSubProcesses(
        $companyPeriodId:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,
    ) {
        companyPeriodSubProcesses(
            companyPeriodId:$companyPeriodId,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
        ) {
            edges {
                node {
                    lastSubProcess {
                        id
                        taskName
                        state
                        createdAt
                        updatedAt
                        message
                        duration
                    }
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

let editMutator

let newMutator

let deleteMutator

let unitQuery

const InvoicePeriodSubProcessUnitQueryTableCreatorCreatorQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class InvoicePeriodSubProcessUnitQueryTableCreatorCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InvoicePeriodSubProcessUnitQueryTableCreatorCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(
            this.constructor.getId(), 
            tableName, 
            InvoicePeriodSubProcessUnitQueryTableCreatorCreatorQueries, 
            new NullClickStrategy(), 
            new SimpleTableDisplayStrategy()
        );
        tableCreator.setDefaultRowHeight(25);

        tableCreator.setDescriptions([
            new TableFieldDescription(
                'taskName',
                new DataMapper("lastSubProcess.taskName"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Process', hideSort: true}
            ),
            new TableFieldDescription(
                'state',
                new DataMapper("lastSubProcess.state"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'State', hideSort: true, componentCreator: new StatusChipComponentCreator()}
            ),
            new TableFieldDescription(
                'createdAt',
                new DataMapper("lastSubProcess.createdAt"),
                new f.FilterDescription(new f.DateFilter('createdAt')),
                {label: 'Executed At', hideSort: true, componentCreator: new DateTimeComponentCreator(), excelDisplay: new DateDisplayCreator()}
            ),
            new TableFieldDescription(
                'duration',
                new DataMapper('lastSubProcess'),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: 'Duration',
                     hideSort: true, 
                     transform: new DataTransformer(
                        ({lastSubProcess}) => checkProcessDuration(
                            lastSubProcess.duration, 
                            lastSubProcess.createdAt, 
                            lastSubProcess.updatedAt, 
                            lastSubProcess.state
                        )
                    )
                }
            ),
            new TableFieldDescription(
                'message',
                new DataMapper("lastSubProcess.message"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Message', hideSort: true}
            )
        ])

        return tableCreator
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InvoicePeriodSubProcessUnitQueryTableCreatorCreator)

export default InvoicePeriodSubProcessUnitQueryTableCreatorCreator
