import React from 'react'
import CanvasJSReact from '../../utils/canvasjs.react'
var CanvasJSChart = CanvasJSReact.CanvasJSChart

export default function PeriodGraph(props) {

    const {className, currency, data} = props
    /* Creates Zerousage line graph and column graph*/ 
    const zeroUsageChartConfig = {
        colorSet: "zeroUsageGraphColors",
        axisX: {
            valueFormatString: "MMM",
            labelFormatter: function (e) {
                const date = new Date(e.label);
                return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
            }
        },
        axisY: {
            prefix: currency.symbol,
        },
        axisY2: {
            title: "",
            lineColor: "transparent",
            tickColor: "transparent",
            labelFontColor: "transparent"
        },
        toolTip: {
            shared: true
        },
        legend: {
            verticalAlign: "center",
            horizontalAlign:"right",
        },

        data: [
        {
            type: "column",
            name: props.t("Cost"),
            showInLegend: true,
            yValueFormatString: `${currency.symbol}#,##0`,
            dataPoints:data.optimizationPeriodTotals.map(item=>({label:item.date, y:item.cost})).reverse()

        },   
        {
            type: "line",
            name: props.t("Quantity"),
            axisYType: "secondary",
            showInLegend: true,
            dataPoints:data.optimizationPeriodTotals.map(item=>({label:item.date, y:item.lineCount, indexLabel:String(item.lineCount)})).reverse()
        }]
    }

    return (
        <CanvasJSChart className={className} options={zeroUsageChartConfig}/>
    )
}

