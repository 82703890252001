/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { gql } from "@apollo/client"

import * as f from '../../components/BPMTable/BPMInputs'
import {
  DataMapper,
  DataTransformer,
  NullClickStrategy,
  OnlyFilterActionDisplayStrategy,
  StatusComponentCreator,
  BooleanExcelDisplayCreator,
  TableFieldDescription,
  GeneralExcelDisplayCreator,
  GeneralChipComponentCreator,
  NumberComponentCreator,
} from '../../components/BPMTable/TableMapperCreator'
import { ExportFooterItemCreator } from "../../components/BPMTable/TableFooterItems"
import tableCreatorCreatorFactoryCreator from "../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator"
import TableCreatorCreator from "../../components/BPMTable/TableCreatorFactory/TableCreatorCreator"

const tableQuery = gql`
    query optimizationByInventory($period:String!,
                              $orderField:String,
                              $first:Int,$last:Int,
                              $after:String,
                              $before:String,
                              $serviceNumber:String,
                              $inventoryStatus:Boolean,
                              $inventoryCustomAttr1:String,
                              $inventoryCustomAttr2:String,
                              $inventoryCustomAttr3:String,
                              $lastName:String
                              $firstName:String,
                              $managerName:String,
                              $managerLastName:String,
                              $isActive:Boolean,
                              $account:String,
                              $vendor:String,
                              $costCenter:String,
                              $currency:String,
                              $previousUsage:Boolean,
                              $reportType:String!,
                              $siteEnterpriseId:String,
                              $siteName:String,
                              $siteState:String,
                              $siteCountry:String,
                              $siteIsActive:Boolean,
                              $siteType:String){
      optimizationByInventory(period:$period,
          orderField:$orderField,
          first:$first,
          last:$last,
          after:$after,
          before:$before,
          serviceNumber:$serviceNumber,
          inventoryStatus:$inventoryStatus,
          inventoryCustomAttr1:$inventoryCustomAttr1,
          inventoryCustomAttr2:$inventoryCustomAttr2,
          inventoryCustomAttr3:$inventoryCustomAttr3,
          lastName:$lastName,
          firstName:$firstName,
          managerName:$managerName,
          managerLastName:$managerLastName,
          isActive:$isActive,
          account:$account,
          vendor:$vendor,
          costCenter:$costCenter,
          previousUsage:$previousUsage,
          reportType:$reportType,
          siteEnterpriseId:$siteEnterpriseId,
          siteName:$siteName,
          siteState:$siteState,
          siteCountry:$siteCountry,
          siteIsActive:$siteIsActive,
          siteType:$siteType,
          ){
            edges{
              node{
                cost
                previousUsage
                convertedCost(currency:$currency)
                user{
                  id
                  firstName
                  isActive
                  lastName
                  manager{
                      firstName
                      lastName
                  }
                }
                inventory{
                  serviceNumber
                  status
                  customAttr1
                  customAttr2
                  customAttr3
                }
                items{
                  costCenter{
                    displayName
                  }
                }
                accounts{
                  edges{
                    node{
                      name
                      id
                    }
                  }
                }
                vendors{
                  edges{
                    node{
                      name
                      id
                    }
                  }
                }
                site{
                  enterpriseId
                  name
                  state
                  isActive
                  country {
                    name
                  }
                  type
                }
              }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
          }
    }
`

const queries = { tableQuery }

class OptimizationTableCreator extends TableCreatorCreator {
  constructor(filters) {
    super(filters)
  }

  static getId = () => {
    return "OptimizationTableCreator"
  }

  getFieldDescriptions = (currentReport) => {
    if (currentReport == 'assigned_to_inactive_site') {
      return this.getAssignedToInactiveSiteDescriptions()
    } else {
      return this.getDefaultDescriptions()
    }
  }

  getDefaultDescriptions = () => {
    return [
      ...this.getBaseFieldDescriptions(),
      ...this.getUserInfoFieldDescriptions(),
      ...this.getCostsFieldDescriptions()
    ]
  }

  getAssignedToInactiveSiteDescriptions = () => {
    return [
      ...this.getBaseFieldDescriptions(),
      new TableFieldDescription(
        'siteEnterpriseId',
        new DataMapper("site.enterpriseId"),
        new f.FilterDescription(new f.TextFilter("siteEnterpriseId")),
        { label: 'Site Enterprise ID' }
      ),
      new TableFieldDescription(
        'siteName',
        new DataMapper("site.name"),
        new f.FilterDescription(new f.TextFilter("siteName")),
        { label: 'Site Name' }
      ),
      new TableFieldDescription(
        'siteState',
        new DataMapper("site.state"),
        new f.FilterDescription(new f.TextFilter("siteState")),
        { label: 'Site State' }
      ),
      new TableFieldDescription(
        'siteCountry',
        new DataMapper("site.country.name"),
        new f.FilterDescription(new f.TextFilter("siteCountry")),
        { label: 'Site Country' }
      ),
      new TableFieldDescription(
        'siteIsActive',
        new DataMapper("site.IsActive"),
        new f.FilterDescription(new f.TextFilter("siteIsActive")),
        { label: 'Site Status' }
      ),
      new TableFieldDescription(
        'siteType',
        new DataMapper("site.type"),
        new f.FilterDescription(new f.TextFilter("siteType")),
        { label: 'Site Type' }
      ),
      ...this.getCostsFieldDescriptions()
    ]
  }

  getBaseFieldDescriptions = () => {
    return [
      new TableFieldDescription(
        'vendors',
        new DataMapper("vendors"),
        new f.FilterDescription(new f.TextFilter("vendor")),
        {
          label: 'Vendor',
          hideSort: true,
          excelDisplay: new GeneralExcelDisplayCreator('Vendor', [{ label: 'Name', path: 'name' }], 0),
          componentCreator: new GeneralChipComponentCreator(["name"], ["name"]),
          transform: new DataTransformer(({ vendors }) => vendors.edges.map(vendor => vendor.node))
        }
      ),
      new TableFieldDescription(
        'accounts',
        new DataMapper("accounts"),
        new f.FilterDescription(new f.TextFilter("account")),
        {
          label: 'Accounts',
          hideSort: true,
          excelDisplay: new GeneralExcelDisplayCreator('Account', [{ label: 'Name', path: 'name' }], 0),
          componentCreator: new GeneralChipComponentCreator(["name"], ["name"], null, false),
          transform: new DataTransformer(({ accounts }) => accounts.edges.map(account => account.node))
        }
      ),
      new TableFieldDescription(
        'serviceNumber',
        new DataMapper("inventory.serviceNumber"),
        new f.FilterDescription(new f.TextFilter("serviceNumber")),
        { label: 'Service Number' }
      ),
      new TableFieldDescription(
        'inventoryStatus',
        new DataMapper("inventory.status"),
        new f.FilterDescription(new f.BooleanFilter("inventoryStatus")),
        { label: 'Inventory Status', componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator() }
      ),
      new TableFieldDescription(
        'inventoryCustomAttr1',
        new DataMapper("inventory.customAttr1"),
        new f.FilterDescription(new f.TextFilter("inventoryCustomAttr1")),
        { label: 'Inventory CA 1', onlyForm: true }
      ),
      new TableFieldDescription(
        'inventoryCustomAttr2',
        new DataMapper("inventory.customAttr2"),
        new f.FilterDescription(new f.TextFilter("inventoryCustomAttr2")),
        { label: 'Inventory CA 2', onlyForm: true }
      ),
      new TableFieldDescription(
        'inventoryCustomAttr3',
        new DataMapper("inventory.customAttr3"),
        new f.FilterDescription(new f.TextFilter("inventoryCustomAttr3")),
        { label: 'Inventory CA 3', onlyForm: true }
      )
    ]
  }

  getUserInfoFieldDescriptions = () => {
    return [
      new TableFieldDescription(
        'managerName',
        new DataMapper("user.manager.firstName"),
        new f.FilterDescription(new f.TextFilter("managerName")),
        { label: "Manager Name" }
      ),
      new TableFieldDescription(
        'managerSurName',
        new DataMapper("user.manager.lastName"),
        new f.FilterDescription(new f.TextFilter("managerLastName")),
        { label: "Manager Surname" }
      ),
      new TableFieldDescription(
        'firstName',
        new DataMapper("user.firstName"),
        new f.FilterDescription(new f.TextFilter("firstName")),
        { label: 'First Name' }
      ),
      new TableFieldDescription(
        'lastName',
        new DataMapper("user.lastName"),
        new f.FilterDescription(new f.TextFilter("lastName")),
        { label: 'Last Name' }
      ),
      new TableFieldDescription(
        'status',
        new DataMapper("user.isActive"),
        new f.FilterDescription(new f.BooleanFilter("isActive")),
        { label: 'User status', componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator() }
      )
    ]
  }

  getCostsFieldDescriptions = () => {
    return [
      new TableFieldDescription(
        'convertedCost',
        new DataMapper("convertedCost"),
        new f.FilterDescription(new f.NullCreator()),
        { label: 'Cost', componentCreator: new NumberComponentCreator(this.parentProps.currency.symbol, 2), excelTransform: new DataTransformer(({ convertedCost }) => Number(convertedCost).toFixed(2)) }
      ),
      new TableFieldDescription(
        'costCenters',
        new DataMapper("items"),
        new f.FilterDescription(new f.NullCreator()),
        {
          label: "Cost Center",
          hideSort: true,
          draggable: false,
          excelDisplay: new GeneralExcelDisplayCreator('Cost Center', [{ label: 'Name', path: 'costCenter.displayName' }]),
          componentCreator: new GeneralChipComponentCreator(['costCenter.displayName'], null, 'Not Assigned'),
          transform: new DataTransformer(({ items }) => items ? items : []),
        }
      ),
      new TableFieldDescription(
        'previousUsage',
        new DataMapper("previousUsage"),
        new f.FilterDescription(new f.BooleanFilter("previousUsage", 'Yes', 'No')),
        { label: 'Usage last 3m', componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator("Yes", "No") }
      ),
    ]
  }

  getTableCreator = (tableName) => {
    const tableCreator = this.createTableCreator(this.constructor.getId(), this.parentProps.title, queries, new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())
    tableCreator.setTableFooterItems([new ExportFooterItemCreator()]);
    tableCreator.setDescriptions(this.getFieldDescriptions(this.parentProps.currentReport));

    return tableCreator;
  }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(OptimizationTableCreator)

export default OptimizationTableCreator;



