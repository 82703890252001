import { createSlice } from '@reduxjs/toolkit'
import produce from 'immer';
import _ from 'lodash';

import BPMTableStructure from './BPMTableStructure';

const setTableState = (state, tableName, property, value) => {
    if (state.currentUser.company) {
        if (tableName in state.BPMTables[state.currentUser.company.id]) {
            state.BPMTables[state.currentUser.company.id][tableName][property] = value
        } else {
            const table = {
                ...BPMTableStructure,
                tableName: tableName,
                [property]: value
            };
            state.BPMTables[state.currentUser.company.id][tableName] = table;
        }
    }
}

const __addBPMTable = (state, action) => {
    if (!(action.payload.tableName in state.BPMTables[state.currentUser.company.id])) {
        state.BPMTables[state.currentUser.company.id][action.payload.tableName] = action.payload
    }
}

const __setBPMTableCount = (state, action) => {
    return setTableState(state, action.payload.tableName, 'count', action.payload.count)
}

const __setBPMTableFilters = (state, action) => {
    return setTableState(state, action.payload.tableName, 'filters', action.payload.filters)
}

const __setBPMTableSelectAllState = (state, action) => {
    return setTableState(state, action.payload.tableName, 'selectAllState', action.payload.selectAllState)
}

const __setBPMTableSelectedRows = (state, action) => {
    return setTableState(state, action.payload.tableName, 'selectedRows', action.payload.selectedRows)
}

const __setBPMTableColumns = (state, action) => {
    state.customConfiguration[action.payload.tableName] = action.payload.tableColumns;
}

const __setUserCustomConfiguration = (state, action) => {
    state.customConfiguration =  action.payload
}

const __setCurrentUser = (state, action) => {
    if (!(action.payload.company.id in state.BPMTables)) {
        state.BPMTables[action.payload.company.id] = {}
    }
    state.currentUser = action.payload
}

const __setLastPasswordChangeRequest = (state, action) => {
    state.currentUser['lastPasswordChangeRequest'] = action.payload
}

const generateMessageObjet = (msg, id) => {
    const message = _.cloneDeep(msg);
    message.id = id;

    return message;
}

const __AddBPMApplicationMessages = (state, action) => {
    var messages = _.cloneDeep(state.applicationSnackMessages)

    let now = new Date();
    let hours = now.getHours().toString().padStart(2, '0');
    let minutes = now.getMinutes().toString().padStart(2, '0');
    let seconds = now.getSeconds().toString().padStart(2, '0');
    let milliseconds = now.getMilliseconds().toString().padStart(3, '0');
    
    const snackId = `${hours}${minutes}${seconds}${milliseconds}`;

    if (Array.isArray(action.payload)) {
        messages = messages.concat(action.payload);
        action.payload.forEach(function callback(snackMessage, index) {
            messages.push(generateMessageObjet(snackMessage, `${snackId}_${index}`));
        });
    } else {
        messages.push(generateMessageObjet(action.payload, `${snackId}_${1}`));
    }

    state.applicationSnackMessages = messages
}

const __RemoveBPMApplicationMessages = (state, action) => {
    return {
        ...state,
        applicationSnackMessages: state.applicationSnackMessages.filter(msg => msg.id != action.payload), 
    };
}

const __setAllCurrencies = (state, action) => {
    state.allCurrencies =  action.payload
}

const __setAllLanguages = (state, action) => {
    state.allLanguages =  action.payload
}

const generatePeriodObject = (data) => {
    var initialDate = new Date()
    if (data) {
        const startDate =  data.period ? data.period.startDate : data.startDate
        var matchFirst = startDate.match(/(\d{4})-(\d{2})-(\d{2})/)
    }
    const aYear = matchFirst ? Number(matchFirst[1]) : initialDate.getFullYear()
    const aMonth = matchFirst ? Number(matchFirst[2]) : initialDate.getMonth()+1

    return {year: aYear, month: aMonth}
}

const __setFirstCompanyPeriod = (state, action) => {
    state.firstCompanyPeriod[state.currentUser.company.id] = generatePeriodObject(action.payload)
}

const __setLastCompanyPeriod = (state, action) => {
    state.lastCompanyPeriod[state.currentUser.company.id] = generatePeriodObject(action.payload)
}

const __setFirstPeriodForProcessedInvoices = (state, action) => {
    state.firstPeriodForProcessedInvoices[state.currentUser.company.id] = generatePeriodObject(action.payload)
}

const __setLatestPeriodForProcessedInvoices = (state, action) => {
    state.latestPeriodForProcessedInvoices[state.currentUser.company.id] = generatePeriodObject(action.payload)
}

const BPMGlobalSlice = createSlice({
    name: 'BPMSlice',
    initialState: {
        currentUser: {},
        customConfiguration: {},
        BPMTables: {},
        applicationSnackMessages: [],
        allCurrencies: [],
        allLanguages: [],
        firstCompanyPeriod: {},
        lastCompanyPeriod: {},
        firstPeriodForProcessedInvoices: {},
        latestPeriodForProcessedInvoices: {},
    },
    reducers: {
        addBPMTable: __addBPMTable,
        setBPMTableCount: __setBPMTableCount,
        setBPMTableFilters: __setBPMTableFilters,
        setBPMTableSelectAllState: __setBPMTableSelectAllState,
        setBPMTableSelectedRows: __setBPMTableSelectedRows,
        setBPMTableColumns: __setBPMTableColumns,
        setUserCustomConfiguration: __setUserCustomConfiguration,
        setCurrentUser: __setCurrentUser,
        setLastPasswordChangeRequest: __setLastPasswordChangeRequest,
        AddBPMApplicationMessages: __AddBPMApplicationMessages,
        RemoveBPMApplicationMessages: __RemoveBPMApplicationMessages,
        setAllCurrencies: __setAllCurrencies,
        setAllLanguages: __setAllLanguages,
        setFirstCompanyPeriod: __setFirstCompanyPeriod,
        setLastCompanyPeriod: __setLastCompanyPeriod,
        setFirstPeriodForProcessedInvoices: __setFirstPeriodForProcessedInvoices,
        setLatestPeriodForProcessedInvoices: __setLatestPeriodForProcessedInvoices,
    },
})

export const {
    addBPMTable,
    setBPMTableCount,
    setBPMTableFilters,
    setBPMTableSelectAllState,
    setBPMTableSelectedRows,
    setBPMTableColumns,
    setUserCustomConfiguration,
    setCurrentUser,
    setLastPasswordChangeRequest,
    AddBPMApplicationMessages,
    RemoveBPMApplicationMessages,
    setAllCurrencies,
    setAllLanguages,
    setFirstCompanyPeriod,
    setLastCompanyPeriod,
    setFirstPeriodForProcessedInvoices,
    setLatestPeriodForProcessedInvoices,
} = BPMGlobalSlice.actions

export default BPMGlobalSlice.reducer